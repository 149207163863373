import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { CircularProgress, IconButton, styled } from "@mui/material";

type TradeButtonProps = {
  url?: string;
  isFetching?: boolean;
  isDisabled: boolean;
};

const TradeButton = ({ url, isFetching, isDisabled }: TradeButtonProps) => {
  return (
    <a
      target="_blank"
      href={url}
      rel="noreferrer"
      onClick={(e) => {
        if (isDisabled) e.preventDefault();
      }}
    >
      <TradeActionButton disabled={isDisabled} size="small">
        {isFetching ? (
          <CircularProgress sx={{ color: (theme) => theme.palette.grey[200] }} size={17} />
        ) : (
          <TrendingUpIcon sx={{ width: 17, height: 17 }} />
        )}
      </TradeActionButton>
    </a>
  );
};

const TradeActionButton = styled(IconButton)({
  width: 32,
  height: 32,
  borderRadius: 4,
  border: "1px solid #4A5053",
});

export default TradeButton;

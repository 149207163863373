import { Stack, Typography, useTheme } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

type AnbotoFetchingErrorProps = {
  errorText?: string;
  hideIcon?: boolean;
};

const AnbotoFetchingError = ({ errorText, hideIcon }: AnbotoFetchingErrorProps) => {
  const theme = useTheme();
  return (
    <Stack flexGrow={1}>
      <Stack justifyContent="center" flexGrow={1} padding={3} alignItems="center">
        {!hideIcon && <WarningAmberIcon sx={{ color: theme.palette.text.secondary }} />}
        <Typography color={theme.palette.text.secondary}>
          {errorText
            ? errorText
            : "Something went wrong. Please try again later, or contact our support team for assistance. Thank you for your patience and understanding"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AnbotoFetchingError;

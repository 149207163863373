import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack, styled, Typography, useTheme } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import Icon from "./menu.svg?react";
import { MenuItemId } from "./constants";
import { DOCS_LINK } from "@src/constants/common";
import { useAppSelector } from "@src/store/hooks";
import { TESTNET_WHITELIST, useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { usePermissions } from "@src/hooks/use-permissions";

type MenuItem = {
  title: string;
  items: {
    id: MenuItemId;
    label: string;
    url: string;
    external?: boolean;
    component?: React.FunctionComponent<{ key: string }>;
  }[];
};

const MENU: MenuItem[] = [
  {
    title: "Trading",
    items: [
      { id: MenuItemId.Cex, label: "CeFi", url: "/trades/cefi" },
      { id: MenuItemId.Dex, label: "DeFi", url: "/trades/defi" },
      { id: MenuItemId.OrderPreferences, label: "Order Preferences", url: "/settings/order-preferences" },
      { id: MenuItemId.TransactionHistory, label: "Transaction History", url: "/settings/transactions-history" },
    ],
  },
  {
    title: "Features",
    items: [
      { id: MenuItemId.Portfolio, label: "Portfolio", url: "/portfolio/cefi/funds" },
      { id: MenuItemId.Metrics, label: "Metrics", url: "/metrics/trading-stats-v2" },
      { id: MenuItemId.PostTradeAnalysis, label: "Post trade analysis", url: "/metrics/post-trade-analysis" },
    ],
  },
  {
    title: "Set up",
    items: [
      { id: MenuItemId.Account, label: "Account", url: "/settings/account" },
      { id: MenuItemId.LinkedCexs, label: "Linked CEXs", url: "/settings/linked/exchanges" },
      { id: MenuItemId.DefiWallets, label: "DeFi wallets", url: "/settings/linked/wallets" },
      { id: MenuItemId.FeeWallets, label: "Fee wallets", url: "/settings/fees" },
    ],
  },
  {
    title: "Anboto",
    items: [
      { id: MenuItemId.FeesDashboard, label: "Fees Dashboard", url: "/fees-dashboard" },
      { id: MenuItemId.Affiliate, label: "Affiliate Program", url: "/affiliate-program" },
      { id: MenuItemId.Competitions, label: "Trading competition", url: "/trading-competitions" },
      { id: MenuItemId.Api, label: "API", url: "/settings/api" },
      { id: MenuItemId.Documentation, label: "Documentation", url: DOCS_LINK, external: true },
    ],
  },
];

const analyticsGroup: MenuItem = {
  title: "Analytics",
  items: [{ id: MenuItemId.FundingRates, label: "Funding Rates", url: "/analytics/funding-rates" }],
};

export const MainMenuDropdown = () => {
  const [open, setOpen] = useState(false);
  const isAnalyticsVisible = useAppSelector((state) => state.user.is_analytics_visible);
  const isStaff = useAppSelector((state) => state.user.is_staff);
  const isOtcVisible = useAppSelector((state) => state.user.is_otc_visible);
  const [items, setItems] = useState<MenuItem[]>([]);
  const isTestnet = useTestnetEnabled();
  const permissions = usePermissions();

  useEffect(() => {
    let _items: MenuItem[] = [...MENU];

    if (isAnalyticsVisible) {
      _items.splice(2, 0, analyticsGroup);
    }

    _items = _items.reduce<MenuItem[]>((res, menuItem) => {
      let groupItems = menuItem.items.filter((item) => (isTestnet ? TESTNET_WHITELIST.includes(item.id) : true), []);
      groupItems = menuItem.items.filter((item) => (!permissions.trade ? item.id !== MenuItemId.Affiliate : true), []);

      if (groupItems.length) return [...res, { ...menuItem, items: groupItems }];

      return res;
    }, []);

    if (isStaff) {
      const anbotoGroup = _items.find((item) => item.title === "Anboto");

      if (!anbotoGroup?.items.find(({ id }) => id === MenuItemId.AdminDashboard))
        anbotoGroup?.items.push({ id: MenuItemId.AdminDashboard, label: "Admin Dashboard", url: "/admin-dashboard" });
    }

    if (isOtcVisible) {
      const tradingGroup = _items.find((item) => item.title === "Trading");
      const setupGroup = _items.find((item) => item.title === "Set up");

      if (!tradingGroup?.items.find(({ id }) => id === MenuItemId.Otc))
        tradingGroup?.items.splice(2, 0, { id: MenuItemId.Otc, label: "OTC", url: "/trades/otc" });

      if (!setupGroup?.items.find(({ id }) => id === MenuItemId.Otc))
        setupGroup?.items.splice(2, 0, { id: MenuItemId.LinkedOtc, label: "Linked OTC", url: "/settings/linked/otc" });
    }

    setItems(_items);
  }, [isAnalyticsVisible, isStaff]);

  return (
    <Stack
      position="relative"
      justifyContent="center"
      height="100%"
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
    >
      <Stack
        className="main-menu-button"
        direction="row"
        alignItems="center"
        sx={{ cursor: "pointer", position: "relative", "& path": open ? { fill: "#fff" } : undefined }}
      >
        <Icon />
        <ExpandMoreIcon sx={{ color: "#8A9296", fontSize: 18 }} />
      </Stack>
      {open && <Menu items={items} />}
    </Stack>
  );
};

const Menu = ({ items }: { items: MenuItem[] }) => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <Root>
      {items.map(({ title, items }) => (
        <Stack key={title}>
          <Typography variant="body2" fontWeight="bold" color={(theme) => theme.palette.text.disabled} mb={1} noWrap>
            {title}
          </Typography>
          {items.map(({ label, url, component, external }) => {
            if (external)
              return (
                <a
                  key={url + label}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", outline: "none" }}
                >
                  <LinkText variant="body2">{label}</LinkText>
                </a>
              );

            if (component)
              return React.createElement(
                component,
                { key: label },
                <LinkText variant="body2" active={!!url && location.pathname.includes(url)}>
                  {label}
                </LinkText>
              );

            return (
              <NavLink
                key={url}
                to={url}
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.primary,
                  display: "block",
                }}
              >
                <LinkText variant="body2" active={!!url && location.pathname.includes(url)}>
                  {label}
                </LinkText>
              </NavLink>
            );
          })}
        </Stack>
      ))}
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  padding: theme.spacing(3),
  position: "absolute",
  background: theme.palette.background.default,
  border: "1px solid #3B4043",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[10],
  top: "100%",
}));

const LinkText = styled(Typography)<{ active?: boolean }>(({ theme, active }) => ({
  display: "block",
  color: active ? theme.custom.colors.primary : theme.palette.text.primary,
  padding: `10px 50px 10px 0`,
  textDecoration: "none",
  outline: "none",
  whiteSpace: "nowrap",
  "&:hover": {
    color: theme.custom.colors.primary,
  },
}));

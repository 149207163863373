import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Login } from "./pages/auth/login/login";
import { HideInTestnet } from "./components/hide-in-testnet/hide-in-testnet";
import { AuthLayout } from "./pages/auth/layout/auth-layout";
import { Registration } from "./pages/auth/registration";
import { ReferralSignUpForm } from "./pages/auth/registration/components/referral-sign-up-form";
import { ForgotPassword } from "./pages/auth/reset-password/forgot-password";
import { ResetPassword } from "./pages/auth/reset-password/reset-password";
import TwoFactorAuth from "./pages/auth/registration/two-factor-auth";
import { CefiOrderPageLazy } from "./pages/cefi/cefi-order-page.lazy";
import { OtcOrderPageLazy } from "./pages/otc/otc-page.lazy";
import { DefiOrderPageLazy } from "./pages/defi/defi-order-page.lazy";
import { InitialRedirect } from "./components/initial-redirect";
import { FundingRatesLazy } from "./pages/analytics/funding-rates/funding-rates.lazy";
import { portfolioRoutes } from "./pages/portfolio/routes";
import { PostTradeAnalysisMetricsLazy } from "./pages/metrics/pages/post-trade-analysis/post-trade-analysis-metrics.lazy";
import { TradingStatsLazy } from "./pages/metrics/pages/metrics/trading-stats.lazy";
import TradingStatsV2 from "./pages/metrics/pages/metrics/trading-stats-v2";
import { ReferralProgramLazy } from "./pages/referral-program/pages/referral-program.lazy";
import { TradingCompetitionsLazy } from "./pages/competitions/trading-competitions.lazy";
import { FeesDashboardPageLazy } from "./pages/fees-dashboard/fees-dashboard.lazy";
import { AdminPageLazy } from "./features/admin/admin-page.lazy";
import { settingsRoutes } from "./pages/settings/routes";
import { IsAuthenticated } from "@src/components/is-authenticated";
import { MainLayout } from "@src/components/layout/main-layout/main-layout";
import { AnalyticsPage } from "@src/pages/analytics/analytics-page";
import MetricsPage from "@src/pages/metrics/metrics-page";
import { FallbackPage } from "@src/pages/fallback";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const appRouter = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<FallbackPage />}>
      <Route
        element={
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        }
      >
        <Route path="/auth/register_via_invite_link" element={<Registration />} />
        <Route path="/referral-code-sign-up" element={<ReferralSignUpForm />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/two-factor-auth" element={<TwoFactorAuth />} />
      </Route>
      <Route element={<IsAuthenticated value={true} />}>
        <Route element={<MainLayout />}>
          <Route path="/trades" element={<Outlet />}>
            <Route path="cefi" element={<CefiOrderPageLazy />} />
            <Route path="otc" element={<OtcOrderPageLazy />} />
            <Route
              path="defi"
              element={
                <HideInTestnet>
                  <DefiOrderPageLazy />
                </HideInTestnet>
              }
            />
          </Route>
          <Route path="/analytics" element={<AnalyticsPage />}>
            <Route path="funding-rates" element={<FundingRatesLazy />} />
            <Route index element={<Navigate to="/analytics/funding-rates" replace />} />
          </Route>
          <Route
            path="/affiliate-program"
            element={
              <HideInTestnet>
                <ReferralProgramLazy />
              </HideInTestnet>
            }
          />
          <Route
            path="/trading-competitions/*"
            element={
              <HideInTestnet>
                <TradingCompetitionsLazy />
              </HideInTestnet>
            }
          >
            <Route path=":competitionId" />
          </Route>
          <Route path="/metrics/*" element={<MetricsPage />}>
            <Route path="post-trade-analysis" element={<PostTradeAnalysisMetricsLazy />} />
            <Route path="trading-stats" element={<TradingStatsLazy />} />
            <Route path="trading-stats-v2" element={<TradingStatsV2 />} />
            <Route index element={<Navigate to="/trading-stats-v2" replace />} />
          </Route>
          <Route path="fees-dashboard" element={<FeesDashboardPageLazy />} />
          <Route path="admin-dashboard" element={<AdminPageLazy />} />
          {portfolioRoutes}
          {settingsRoutes}
          <Route path="*" element={<InitialRedirect />} />
        </Route>
      </Route>
    </Route>
  )
);

import { List } from "@mui/material";
import { FixedSizeList } from "react-window";
import { TokenWithBalance } from "@src/pages/defi/types";
import { TokenItem } from "@src/components/token-item";

type TokenListProps = {
  activeTokens: TokenWithBalance[];
  isLoading: boolean;
  onTokenClick(token: TokenWithBalance): void;
  height: number;
};

const renderRow = ({ index, style, data }) => (
  <TokenItem
    key={index}
    style={style}
    token={data.data[index]}
    onTokenClick={data.onTokenClick}
    isLoading={data.isLoading}
  />
);

export const TokenList = ({ activeTokens, isLoading, onTokenClick, height }: TokenListProps) => (
  <List dense sx={{ padding: 0 }}>
    <FixedSizeList
      itemSize={60}
      itemCount={activeTokens.length}
      overscanCount={5}
      itemData={{ data: activeTokens, onTokenClick, isLoading }}
      height={height}
      width="100%"
    >
      {renderRow}
    </FixedSizeList>
  </List>
);

import { Box, Stack, Typography, useTheme } from "@mui/material";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { formatUsd } from "@src/utils/format";

const TakerVsMakerTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <Box sx={{ background: "#3B4043", padding: 1, borderRadius: 1 }}>
        {payload.map((item, i) => (
          <Stack key={item?.name} direction="row" gap={0.5} alignItems="center">
            <Box width={10} height={10} sx={{ borderRadius: "50%", background: `${item.color}` }}></Box>
            <Typography variant="caption">
              <Typography variant="caption" color={theme.palette.text.secondary}>
                {item.name}:
              </Typography>{" "}
              {parseFloat(Number(item?.value ? item.value : 0).toFixed(2))}% (
              {formatUsd(i === 0 ? item?.payload?.taker_value : item?.payload?.maker_value)})
            </Typography>
          </Stack>
        ))}
      </Box>
    );
  }

  return null;
};

export default TakerVsMakerTooltip;

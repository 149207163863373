import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { Stack, Typography, useTheme } from "@mui/material";
import { COLORS } from "@src/pages/portfolio/constant";
import TakerVsMakerTooltip from "@src/pages/metrics/components/taker-vs-maker-chart/taker-vs-maker-tooltip";

type TakerVsMakerChartProps = {
  takerVsMakerRate: { taker: number; maker: number; taker_value: number; maker_value: number };
};

const TakerVsMakerChart = ({ takerVsMakerRate }: TakerVsMakerChartProps) => {
  const theme = useTheme();

  const data = [{ name: "Taker and Maker", ...takerVsMakerRate }];

  return (
    <Stack position="relative" left={-60}>
      <Typography marginLeft={7.5} variant="h6" fontWeight="bold">
        Trading type (only for CEFI)
      </Typography>
      <BarChart width={500} height={100} data={data} layout="vertical">
        <CartesianGrid stroke={theme.custom.background.secondary} vertical={false} strokeDasharray="5 5" />
        <XAxis type="number" tickFormatter={(tick) => parseInt(tick) + "%"} domain={[0, 100]} />
        <YAxis tick={false} type="category" dataKey="name" />
        <Tooltip content={<TakerVsMakerTooltip />} cursor={{ fill: "transparent" }} />
        <Legend
          formatter={(value) => (
            <span className="text-color-class">
              {value} {value === "taker" ? "(Aggressive)" : "(Passive)"}
            </span>
          )}
        />
        <Bar dataKey="taker" fill={COLORS[0]} stackId="a" />
        <Bar dataKey="maker" fill={COLORS[3]} stackId="a" />
      </BarChart>
    </Stack>
  );
};
export default TakerVsMakerChart;

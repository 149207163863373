export const extractJSON = (str: string): string | null => {
  let firstOpen, firstClose, candidate;
  firstOpen = str.indexOf("{", firstOpen + 1);

  do {
    firstClose = str.lastIndexOf("}");

    if (firstClose <= firstOpen) {
      return null;
    }

    do {
      candidate = str.substring(firstOpen, firstClose + 1);

      try {
        JSON.parse(candidate);

        return candidate;
      } catch {
        console.log("...failed");
      }
      firstClose = str.substr(0, firstClose).lastIndexOf("}");
    } while (firstClose > firstOpen);

    firstOpen = str.indexOf("{", firstOpen + 1);
  } while (firstOpen !== -1);

  return null;
};

import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "@mui/material";
import { COLORS } from "@src/pages/portfolio/constant";
import TradesChartTooltip from "@src/pages/metrics/components/trades-chart/trades-chart-tooltip";
import DateTick from "@src/pages/metrics/components/date-tick/date-tick";
import { TradesChartData } from "@src/store/apis/anbotoApi/types";
import { formatUsdBigAmounts } from "@src/pages/metrics/helpers";
import { useAppSelector } from "@src/store/hooks";

type TradesChartProps = {
  tradesChartData: TradesChartData[];
};

const TradesChart = ({ tradesChartData }: TradesChartProps) => {
  const theme = useTheme();
  const tickerRange = useAppSelector((state) => state.metrics.tradesMetricsFilters.groupBy);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <ComposedChart
        width={500}
        height={250}
        data={tradesChartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={30}
      >
        <CartesianGrid stroke={theme.custom.background.secondary} vertical={false} strokeDasharray="5 5" />
        <XAxis height={60} tick={(props) => <DateTick tickerRange={tickerRange} {...props} />} dataKey="date" />
        <YAxis yAxisId="left" orientation="left" tickFormatter={formatUsdBigAmounts} dataKey="trades" />
        <YAxis yAxisId="right" orientation="right" tickFormatter={formatUsdBigAmounts} dataKey="cumulativeSum" />
        <Tooltip content={<TradesChartTooltip />} />
        <Bar yAxisId="left" dataKey="trades" fill={COLORS[0]} />
        <Line yAxisId="right" dataKey="cumulativeSum" stroke={COLORS[1]} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default TradesChart;

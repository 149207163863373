import { useForm, useFormContext, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { ChainId } from "@src/pages/defi/types";
import { CHAIN_ID_DEFAULT_TOKEN_PAIRS } from "@src/pages/defi/constants";
import formValidator from "@src/utils/formValidator";

export type SendCryptoFormValues = {
  accountFrom: string;
  accountFromName: string;
  accountTo: string;
  chainId: ChainId;
  tokenAddress: string;
  tokenAmount: string;
  tokenBalance: string;
};

const defaultValues = {
  chainId: ChainId.ETHEREUM,
  tokenAddress: CHAIN_ID_DEFAULT_TOKEN_PAIRS[ChainId.ETHEREUM].from.address,
};

const schema = formValidator.object({
  accountFrom: formValidator.string().required(),
  accountFromName: formValidator.string(),
  accountTo: formValidator
    .string()
    .required("Please specify the wallet address send to")
    .test("address to", "This is not a valid address that match your selected network", function (value?: string) {
      try {
        ethers.utils.getAddress(value!);

        return true;
      } catch {
        return false;
      }
    }),
  chainId: formValidator.number().required(),
  tokenAddress: formValidator.string().required(),
  tokenBalance: formValidator.string().required(),
  tokenAmount: formValidator
    .string()
    .required("Required")
    .test("token amount", "Insufficient balance", function (value?: string) {
      return !!value && new BigNumber(value).gt(0) && new BigNumber(this.parent.tokenBalance).gte(value);
    }),
});

export const useSendCryptoForm = (): UseFormReturn<SendCryptoFormValues> =>
  useForm<SendCryptoFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

export const useSendCryptoFormContext = () => {
  return useFormContext<SendCryptoFormValues>();
};

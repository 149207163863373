import { Stack, Typography } from "@mui/material";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { PortfolioType } from "@src/store/slices/portfolioSlice";

type EmptyPortfolioListProps = {
  portfolioType: PortfolioType;
};
export const EmptyPortfolioList = ({ portfolioType }: EmptyPortfolioListProps) => {
  return (
    <Stack
      display="flex"
      flexDirection="column"
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <AccountBalanceOutlinedIcon sx={{ color: "#4A5053", height: 50, width: 50 }} />
      <Typography fontWeight={400} fontSize={14} color="#4A5053">
        You don&apos;t have linked {portfolioType === PortfolioType.CEFI ? "exchange" : "wallet"}, <br />
        please link with your {portfolioType === PortfolioType.CEFI ? "exchange account" : "wallet"}
      </Typography>
    </Stack>
  );
};

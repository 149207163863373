import dayjs from "dayjs";
import { OrderSide } from "@src/store/apis/anbotoApi/types";
import { DaylyMetricsData } from "@src/store/apis/anbotoApi/orders/types";
import { Metrics } from "@src/store/slices/metricsSlice";

type TMetrics = {
  key: string;
  volume: number;
  volumeCummulative: number;
  buy: number;
  sell: number;
  taker: number;
  maker: number;
  cefi: number;
  defi: number;
};

const emptyValues = {
  key: "",
  volume: 0,
  volumeCummulative: 0,
  buy: 0,
  sell: 0,
  taker: 0,
  maker: 0,
  cefi: 0,
  defi: 0,
};

export const prepareMetricsData = (
  data: DaylyMetricsData,
  period: "month" | "week" | "day",
  filter?: Partial<
    Pick<
      Metrics["tradesMetricsFilters"],
      "assetClasses" | "exchanges" | "sides" | "strategies" | "subaccounts" | "tradeTypes"
    >
  >
) => {
  const filters =
    filter &&
    (Object.keys(filter).reduce(
      (res, key) => ({
        ...res,
        [key]: filter[key].reduce((acc, el) => ({ ...acc, [el]: true }), {}),
      }),
      {}
    ) as Record<keyof Metrics["tradesMetricsFilters"], Record<string, boolean>>);

  const res: {
    table: Record<string, TMetrics>;
    exchanges: Record<string, { value: number; isDefi: boolean }>;
    strategies: Record<string, boolean>;
    subaccounts: Record<string, boolean>;
  } = {
    table: {},
    exchanges: {},
    strategies: {},
    subaccounts: {},
  };

  Object.keys(data).forEach((key) => {
    const date = key.split(" ")[0];
    const [year, month] = date.split("-");

    const dayKey = date;
    const weekKey = dayjs(date).day(0).format("YYYY-MM-DD");
    const monthKey = `${year}-${month}-01`;

    const dateKey = period === "day" ? dayKey : period === "week" ? weekKey : monthKey;

    const tableData = res.table;

    if (!tableData[dateKey]) tableData[dateKey] = { ...emptyValues, key: dateKey };

    const current = tableData[dateKey];

    data[key].forEach(
      ({
        asset_class,
        exchange_id,
        executed_usd,
        // fee_cost_usd_hist,
        // fee_cost_usd_now,
        is_defi,
        maker_or_taker,
        // n_parents,
        side,
        strategy,
        subaccount,
        // trader_id,
      }) => {
        if (
          filters?.tradeTypes &&
          [filters.tradeTypes, filters.strategies, filters.assetClasses, filters.exchanges, filters.subaccounts].some(
            (el) => el
          )
        ) {
          if (
            (filters.tradeTypes.CEFI && !filters.tradeTypes.DEFI && is_defi === "True") ||
            (filters.tradeTypes.DEFI && !filters.tradeTypes.CEFI && is_defi === "False")
          )
            return false;

          if (filters.strategies && !filters.strategies[strategy]) return false;

          if (filters.assetClasses && !filters.assetClasses[asset_class]) return false;

          if (filters.exchanges && !filters.exchanges[exchange_id]) return false;

          if (filters.subaccounts && !filters.subaccounts[subaccount]) return false;
        }

        const val = executed_usd || 0;
        current.volume += val;

        current.buy += side === OrderSide.BUY ? val : 0;
        current.sell += side === OrderSide.SELL ? val : 0;
        current.taker += maker_or_taker === "taker" ? val : 0;
        current.maker += maker_or_taker === "maker" ? val : 0;
        current.cefi += is_defi === "False" ? val : 0;
        current.defi += is_defi === "True" ? val : 0;

        if (!res.exchanges[exchange_id]) {
          res.exchanges[exchange_id] = {
            value: 0,
            isDefi: false,
          };
        }

        res.exchanges[exchange_id].value += val;
        res.exchanges[exchange_id].isDefi = is_defi === "True";

        res.strategies[strategy] = true;
        res.subaccounts[subaccount] = true;
      }
    );
  });

  return res;
};

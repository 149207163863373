import { DailyMetricsResponse } from "./types";
import { anbotoApi } from "@src/store/apis/anbotoApi/index";

export const anbotoOrders = anbotoApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDailyMetrics: builder.query<DailyMetricsResponse, { start: string; end: string }>({
      providesTags: ["DAILY_METRICS"],
      query: ({ start, end }) => ({
        method: "GET",
        url: `/v3/orders/daily_metrics_request/`,
        params: {
          start_time: start,
          end_time: end,
        },
      }),
    }),
  }),
});

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Mutex } from "async-mutex";
import { DateTime } from "luxon";
import * as Sentry from "@sentry/react";
import _throttle from "lodash/throttle";
import {
  AddParticipantParams,
  ClipSizeType,
  CreateAccountParams,
  CreateTGParams,
  CreateTGResult,
  DefiOrder,
  DefiOrderUpdateMessage,
  Exchange,
  ExchangeConfig,
  ExchangeVolumeDataParams,
  ExchangeVolumeDataResult,
  GetCefiParentOrdersParams,
  GetDefiOrdersQueryParams,
  GetExchangeSymbolMinAmountDataResult,
  GetGasOracleResult,
  GetMarketDataParams,
  GetMarketDataResult,
  GetMetricsMonthsResult,
  GetMonthMetricsResult,
  GetWalletsResult,
  OrderExecutionStrategy,
  OrdersCurrentTab,
  OrderStatuses,
  OrderSymbolPrice,
  PaginationResult,
  ParentOrder,
  ParentOrderCancelParams,
  ParentOrderFormParams,
  ParentOrderMsgCode,
  ParentOrderStatuses,
  // RefreshTokenResult,
  SigninParams,
  SuccessDataResult,
  SymbolAccountBalance,
  Team,
  Toggle2faParams,
  TradingviewSymbol,
  UserAccountsResult,
  UserDetails,
  UserNotificationSettings,
  VerifParams,
  Wallet,
  GetExchangesSymbolDataResponse,
  PreTradeAnalysisResult,
  PreTradeAnalysisParams,
  OrderSymbol,
  GetBaseCoinPriceResult,
  GetBaseCoinPriceParams,
  GetFundingRatesParams,
  GetFundingRatesResult,
  StartSavedOrderParams,
  DeleteSavedOrderParams,
  GetGasAmountEstimationParams,
  GetGasAmountEstimationResult,
  GetSwapQuoteParams,
  GetSwapQuoteResult,
  GetPromosResult,
  GetMetricsTradeResult,
  GetMetricsTradeParams,
  GetMetricsTradesGroupedParams,
  GetMetricsTradesGroupedResult,
  GetAllParentOrdersParams,
  AllParentOrder,
  MetricsPageOrderExportParams,
  ValidateApiKeyResult,
  ValidateApiKeyParams,
  CreateExchangeConfigResult,
  CreateExchangeConfigParams,
  FavoriteSymbol,
  GetPostTradeAnalysisDataResult,
  GetPostTradeAnalysisDataParams,
  PreTradeAnalysisAgnosticParams,
  PreTradeAnalysisAgnosticResult,
  GetLeaderBoardResult,
  SetOrderPreferencesParams,
  GetDailyDashboardResult,
  GetMonthlyDashboardResult,
  GetFeeManagerBalancesResults,
  TradingStyle,
  PlacementMode,
  PlacementValue,
  PlacementCancelValue,
  VerifyInviteResult,
  GetPostTradeAnalysisMetricsDataResult,
  GetPostTradeAnalysisMetricsDataParams,
  CefiExchangeId,
  GetOrdersPeriod,
} from "./types";
import { prepareNumericId, prepareNumericValue, reloadParentOrders } from "./helpers";
import { ChangePasswordFormFieldValues } from "@src/pages/settings/change-password/useChangePasswordForm";
import { compareCaseInsensitive } from "@src/pages/defi/utils";
import {
  setLiquiditySources,
  setOrderPreferences,
  setUser,
  setUserTokenWatchlist,
} from "@src/store/actions/user.action";
import { ChainId } from "@src/pages/defi/types";
import {
  Allowance,
  GetDefiOrdersParams,
  GetQuoteParams,
  GetQuoteResult,
  SigninResult,
  SocialAccount,
  UserPasswordLinkResponse,
  UserPasswordResetResponse,
} from "@src/store/apis/anbotoApi/types";
import { getTradingDurationSec } from "@src/pages/cefi/order-form/utils";
import { getExchangeIdByExchangeName, is2faError, isDeactivated } from "@src/store/apis/anbotoApi/utils";
import { prepareExchange } from "@src/pages/cefi/order-symbol-chart/anboto-datafeed/helpers";
import { getHttpUrl, getWsUrl } from "@src/utils/url";
import { ComplexProtocol, UserToken } from "@src/store/apis/debankApi/types";
import { SUPPORTED_CHAINS } from "@src/store/apis/debankApi/constants";
import { HOST, WS_HOST } from "@src/store/apis/anbotoApi/constants";
import { DefaultStatusFilter } from "@src/components/orders-table-filters/types";
import { toggleLoadingSymbol } from "@src/store/slices/watchlistSlice";
import { RootState } from "@src/store/types";
import { changeArrayOrder } from "@src/components/watchlist/helpers";
import { getExpirationTimeMs } from "@src/pages/cefi/order-form/fields/ExpirationTimeSelect";
import { GetChainPriceResult } from "@src/store/apis/coingeckoApi/types";
import { CHAIN_ID_ASSET_PLATFORM } from "@src/store/apis/coingeckoApi/constants";
import { initializeWidgets, WidgetsViewState } from "@src/features/widgets-layout/store/widgets.slice";
import wsSubscribe from "@src/subscriptions/ws-subscribe";
import { getAccountSubscription } from "@src/subscriptions";
import { setExchangeApiKeyError, updatePromos } from "@src/store/slices/uiSettingsSlice";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { MAIN_ACCOUNT_VALUE } from "@src/pages/cefi/constants";
import { Auth } from "@src/features/auth";
import { getTradingStyle } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";
import { WidgetsGridView } from "@src/features/widgets-layout/types";

const baseQuery = fetchBaseQuery({
  baseUrl: `${HOST ? getHttpUrl(HOST) : ""}/api`,
  credentials: "include",
  headers: { "Access-Control-Allow-Origin": window.location.origin },
  prepareHeaders: async (headers) => {
    const { idToken } = await Auth.getAuthTokens();
    if (idToken) {
      headers.set("authorization", idToken);
    }

    return headers;
  },
});

const mutex = new Mutex();

const attemps = { current: 0 };

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error.status === 403 || result.error.status === 401)) {
    if (isDeactivated(result.error.data as any)) {
      const errorText = is2faError(result.error.data as any) ? "2faError" : "user is deactivated";

      Sentry.captureException(new Error(`${errorText} log user out` + JSON.stringify(result.error)));

      Auth.signOut();
    } else {
      attemps.current++;

      if (attemps.current > 2) Auth.signOut();
      // checking whether the mutex is locked
      else if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          await Auth.fetchAuthSession({ forceRefresh: true });

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } catch (error) {
          console.log(error);

          Sentry.captureException(new Error(`Refresh auth token failed`));

          Auth.signOut();
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();

        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  attemps.current = 0;

  return result;
};

export const anbotoApi = createApi({
  reducerPath: "anbotoApi",
  tagTypes: [
    "DAILY_METRICS",
    "DEFI_ORDERS",
    "CEFI_ORDERS",
    "EXCHANGE_CONFIGS",
    "WALLETS",
    "SOCIAL_ACCOUNTS",
    "USER_NOTIFICATION_SETTINGS",
    "USER_ACCOUNTS",
    "USER_TOKENS_LIST",
    "PARENT_CEFI_ORDERS",
    "PARENT_CEFI_SAVED_ORDERS",
    "PARENT_DEFI_ORDERS",
    "PORTFOLIO_EXCHANGES_LIST",
    "PORTFOLIO_WALLETS_LIST",
    "PORTFOLIO_TASK_STATUS",
    "GAS_AMOUNT_ESTIMATION",
    "CEFI_POSITIONS",
    "API_KEYS",
    "SYMBOL_BALANCE",
    "PARENT_CEFI_TRIGGER_COUNT",
  ],
  keepUnusedDataFor: 0,
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    // quote that rely on coingecko in the BE
    getQuote: builder.query<GetQuoteResult, GetQuoteParams>({
      query: ({ fromTokenAddress, toTokenAddress, chainId, payAmount }) => ({
        url: `/dex/bot/dex_get_quote/`,
        params: {
          aggregator: "0x",
          network_id: chainId,
          from_token: fromTokenAddress,
          to_token: toTokenAddress,
          amount: payAmount,
        },
      }),
    }),
    // quote that rely on zeroX in the BE (newer implementation)
    getSwapQuote: builder.query<GetSwapQuoteResult, GetSwapQuoteParams>({
      query: ({
        chainId,
        sellToken,
        sellTokenDecimals,
        buyToken,
        buyTokenDecimals,
        buyAmount,
        sellAmount,
        slippage,
      }) => ({
        url: `/defi/get_swap_quote_sc/`,
        params: {
          network_id: chainId,
          sell_token: sellToken,
          sell_token_decimals: sellTokenDecimals,
          buy_token: buyToken,
          buy_token_decimals: buyTokenDecimals,
          buy_amount: buyAmount,
          sell_amount: sellAmount,
          slippage_percentage: slippage.length ? parseFloat(slippage) / 100 : 0,
        },
      }),
    }),
    // THIS IS COINGECKO PROXY ENDPOINT
    getBaseCoinPrice: builder.query<GetBaseCoinPriceResult, GetBaseCoinPriceParams>({
      query: ({ ids, vsCurrencies }) => ({
        url: `/dex/bot/base_token_price`,
        params: {
          ids,
          vs_currencies: vsCurrencies,
        },
      }),
      transformResponse: (response, meta, { chainId }) =>
        (response as GetChainPriceResult)[CHAIN_ID_ASSET_PLATFORM[chainId]] || { usd: 0 },
    }),
    getDefiOrders: builder.query<PaginationResult<DefiOrder>, GetDefiOrdersParams>({
      providesTags: (result) =>
        result
          ? result.results.map(({ uuid }) => ({
              type: "DEFI_ORDERS",
              id: uuid,
            }))
          : ["DEFI_ORDERS"],
      query: ({ currentTab, period, status, limit }) => {
        const params: GetDefiOrdersQueryParams = {
          current_tab: currentTab,
          date_time_limit: period,
          status,
          limit,
        };

        return {
          url: "/dex/bot/dex_bot_executions/",
          params,
        };
      },
      // https://redux-toolkit.js.org/rtk-query/usage/streaming-updates#streaming-update-examples
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, updateCachedData, getState, dispatch }) {
        const refetching = { inProgress: false };
        const state = getState();
        const userTeamId = (state as unknown as { user: { team_uuid: string } }).user.team_uuid;
        await cacheDataLoaded;

        const teamWs = await wsSubscribe({
          url: getWsUrl(`${WS_HOST}/stream/team_dex_uuid/${userTeamId}/`),
          onmessage: (e) => {
            const { message: defiOrderUpdateMessage }: { message: DefiOrderUpdateMessage } = JSON.parse(e.data);
            updateCachedData((draft) => {
              if (defiOrderUpdateMessage?.bot_execution_uuid) {
                const defiOrder = draft.results.find((order) =>
                  compareCaseInsensitive(order.uuid, defiOrderUpdateMessage.bot_execution_uuid)
                );

                if (defiOrder) {
                  defiOrder.status = defiOrderUpdateMessage.status.toLowerCase() as OrderStatuses;
                  defiOrder.errors = { errors: defiOrderUpdateMessage.errors || "" };
                  defiOrder.infos = {
                    ...(defiOrder.infos || {}),
                    executed_quantity: defiOrderUpdateMessage.executed_quantity || 0,
                    quantity: defiOrderUpdateMessage.quantity,
                    average_closed_orders_price: parseFloat(defiOrderUpdateMessage.average_closed_orders_price),
                  };
                } else {
                  // @TODO: implement fetching single data by id and add it to the list (when api ready)
                  if (!refetching.inProgress) {
                    refetching.inProgress = true;
                    dispatch(
                      anbotoApi.endpoints.getDefiOrders.initiate(arg, { subscribe: false, forceRefetch: true })
                    ).then(() => (refetching.inProgress = false));
                  }
                }
              }
            });
          },
        });
        await cacheEntryRemoved;
        teamWs.close();
      },
    }),
    stopDefiOrder: builder.mutation<null, { id: string }>({
      invalidatesTags: (result, error, arg) => [{ type: "DEFI_ORDERS", id: arg.id }],
      query: ({ id }) => ({
        method: "PUT",
        url: `/dex/bot/${id}/stop/`,
        body: { bot_model: "dex" },
      }),
    }),
    searchTradingviewSymbol: builder.query<{ success: boolean; data: TradingviewSymbol[] }, string>({
      query: (symbol) => ({
        url: `/markets/symbol_search_tradingview/`,
        params: { symbol },
      }),
    }),
    getExchangeVolumeData: builder.query<ExchangeVolumeDataResult, ExchangeVolumeDataParams>({
      query: ({
        symbol,
        exchange_name,
        market_type,
        quantity,
        frequency,
        number_of_slices,
      }: ExchangeVolumeDataParams) => ({
        url: "/markets/exchange_volume_data/",
        params: {
          symbol: symbol || undefined,
          exchange_name: exchange_name || undefined,
          market_type: market_type || undefined,
          quantity: quantity || undefined,
          frequency: frequency ? frequency / 60 : undefined,
          number_of_slices: number_of_slices || undefined,
        },
      }),
    }),
    getPreTradeAnalysis: builder.query<PreTradeAnalysisResult, PreTradeAnalysisParams>({
      query: ({
        symbol,
        exchange,
        asset_class,
        strategy,
        quantity,
        clip_size_type,
        clip_size_val,
        duration_seconds,
      }: PreTradeAnalysisParams) => ({
        url: "/v3/orders/pre_trade_analysis_cefi_aware/",
        params: {
          symbol,
          exchange,
          asset_class,
          strategy,
          quantity,
          clip_size_type,
          clip_size_val,
          duration_seconds,
        },
      }),
    }),
    getPreTradeAnalysisAgnosticData: builder.query<PreTradeAnalysisAgnosticResult, PreTradeAnalysisAgnosticParams>({
      query: ({ symbol, exchange, asset_class }: PreTradeAnalysisParams) => ({
        url: "/v3/orders/pre_trade_analysis_cefi_agnostic/",
        params: {
          symbol,
          exchange,
          asset_class,
        },
      }),
    }),
    getSymbolAccountBalance: builder.query<SymbolAccountBalance, { symbol: OrderSymbol; account: string }>({
      providesTags: ["SYMBOL_BALANCE"],
      query: (x) => ({
        url: `/wallets/cefi_balance_for_symbol/`,
        params: {
          exchange_name: x.symbol.exchange,
          market_type: x.symbol.market_type,
          symbol: x.symbol.symbol,
          subaccount: x.account === MAIN_ACCOUNT_VALUE ? undefined : x.account,
        },
      }),
    }),
    getExchanges: builder.query<PaginationResult<Exchange>, null | { isDefi: boolean }>({
      query: (params) => ({
        url: `/exchanges/`,
        params: { limit: 100, offset: 0, is_defi: params?.isDefi },
      }),
      transformResponse: (response: PaginationResult<Exchange>, meta, arg) => {
        if (!arg) return response;

        const { results, ...rest } = response;
        return {
          results: results.filter((el) => el.is_defi === arg?.isDefi),
          ...rest,
        };
      },
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded, getState }) {
        if (arg?.isDefi) {
          const { data } = await cacheDataLoaded;

          const state = getState();
          const persistedUserLiquiditySources = (state as RootState).user.liquiditySources || {};

          const liquiditySourcesData = data ? [...data.results] : [];
          const liquiditySources = liquiditySourcesData.reduce(
            (result, current) => ({
              ...result,
              [current.exchange_id]: {
                value: persistedUserLiquiditySources[current.exchange_id]
                  ? persistedUserLiquiditySources[current.exchange_id].value
                  : true,
                name: current.name,
              },
            }),
            {}
          );
          dispatch(setLiquiditySources(liquiditySources));
        }
      },
    }),
    getExchangeConfigs: builder.query<PaginationResult<ExchangeConfig>, string | null>({
      providesTags: ["EXCHANGE_CONFIGS"],
      query: (teamId) => ({
        url: `/settings/exchanges/`,
        params: { limit: 100, offset: 0, team: teamId || undefined },
      }),
      async onQueryStarted(arg, api) {
        try {
          const casheData = await api.queryFulfilled;

          (casheData?.data?.results || []).forEach((apiKey) => {
            if (apiKey?.error) api.dispatch(setExchangeApiKeyError({ [apiKey.uuid]: apiKey.error }));
          });
        } catch (e) {
          console.log(e);
        }
      },
    }),
    deleteExchangeConfig: builder.mutation<null, string>({
      invalidatesTags: ["EXCHANGE_CONFIGS"],
      query: (id) => ({
        method: "DELETE",
        url: `/settings/exchanges/${id}/`,
      }),
    }),
    createExchangeConfig: builder.mutation<CreateExchangeConfigResult, CreateExchangeConfigParams>({
      invalidatesTags: ["EXCHANGE_CONFIGS"],
      query: ({ exchange, apiKey, secretKey, subaccountTitle, password }) => ({
        method: "POST",
        url: `/settings/exchanges/`,
        body: {
          exchange: exchange,
          api_key: apiKey,
          secret_key: secretKey,
          password: password || undefined,
          subaccount_title: subaccountTitle || undefined,
        },
      }),
    }),
    updateExchangeConfig: builder.mutation<
      null,
      {
        id: string;
        exchange: number;
        apiKey?: string;
        secretKey?: string;
        stableCoin?: string;
        subaccountTitle?: string;
        password?: string;
      }
    >({
      invalidatesTags: ["EXCHANGE_CONFIGS"],
      query: ({ id, exchange, apiKey, secretKey, subaccountTitle, password }) => ({
        method: "PATCH",
        url: `/settings/exchanges/${id}/`,
        body: {
          exchange: exchange,
          api_key: apiKey || undefined,
          secret_key: secretKey || undefined,
          subaccount_title: subaccountTitle || undefined,
          password: password || undefined,
        },
      }),
    }),
    getOrderSymbolPrice: builder.query<SuccessDataResult<OrderSymbolPrice>, OrderSymbol>({
      query: ({ symbol, market_type, exchange }) => ({
        url: `/markets/get_symbol_price/`,
        providesTags: ["SYMBOL_PRICE"],
        params: {
          symbol,
          market_type,
          // exchange_name: exchange,
          // @TODO use exchange_id from algolia instead this function when it's available
          exchange_id: getExchangeIdByExchangeName(exchange),
        },
      }),
    }),
    getPossibleTeams: builder.query<PaginationResult<{ possible_teams: Team[] }>, null>({
      query: () => ({
        url: `/settings/teams/list/`,
      }),
    }),
    getCurrentTeam: builder.query<PaginationResult<{ team_uuid: string }>, null>({
      query: () => ({
        url: `/settings/teams/current/`,
      }),
    }),
    setCurrentTeam: builder.mutation<null, { userId: string; teamId: string }>({
      query: ({ userId, teamId }) => ({
        method: "PUT",
        url: `/settings/teams/current/${userId}/`,
        body: { team_uuid: teamId },
      }),
    }),
    getUserDetails: builder.query<UserDetails, null>({
      query: () => ({
        url: `/rest-auth/user/`,
      }),
      onCacheEntryAdded: async (arg, api) => {
        await api.cacheDataLoaded;
        const cacheEntry = api.getCacheEntry();
        if (cacheEntry.data) {
          const userData = { ...cacheEntry.data };
          if (userData.token_pair_watchlist) {
            userData.token_pair_watchlist = userData.token_pair_watchlist
              ? [...userData.token_pair_watchlist]
                  .sort((a, b) => (a.position || 0) - (b.position || 0))
                  .map((s) => {
                    const symbol = { ...s };
                    if (symbol.asset_class) symbol.market_type = symbol.asset_class;
                    return symbol;
                  })
              : [];
          }
          api.dispatch(setUser(userData));

          api.dispatch(
            initializeWidgets({
              [WidgetsGridView.CEFI]: userData.layout_cefi,
              [WidgetsGridView.DEFI]: userData.layout_defi,
              [WidgetsGridView.OTC]: userData.layout_otc,
            } as Record<WidgetsGridView, WidgetsViewState>)
          );
        }
      },
    }),
    saveResetPassword: builder.mutation<any, UserPasswordResetResponse>({
      query: (body) => ({
        url: `/rest-auth/password/reset/confirm/`,
        method: "POST",
        body,
      }),
    }),
    sendPasswordResetLink: builder.mutation<any, UserPasswordLinkResponse>({
      query: (body) => ({
        url: `/rest-auth/password/reset/`,
        method: "POST",
        body,
      }),
    }),
    getSocialAccounts: builder.query<SocialAccount | undefined, any>({
      providesTags: ["SOCIAL_ACCOUNTS"],
      query: () => ({
        url: `/socialaccounts/`,
      }),
    }),
    createTelegramGroup: builder.mutation<CreateTGResult, CreateTGParams>({
      query: (body) => ({
        url: `/tg_groups/`,
        method: "POST",
        body,
      }),
    }),
    connectGoogleAccount: builder.mutation<any, { access_token: string }>({
      invalidatesTags: ["SOCIAL_ACCOUNTS"],
      query: (body) => ({
        method: "POST",
        url: `/rest-auth/google/connect/`,
        body,
      }),
    }),
    disconnectSocialAccount: builder.mutation<any, { id: number }>({
      invalidatesTags: ["SOCIAL_ACCOUNTS"],
      query: ({ id }) => ({
        method: "POST",
        url: `/socialaccounts/${id}/disconnect/`,
      }),
    }),
    googleAuth: builder.mutation<any, { access_token: string }>({
      query: (body) => ({
        method: "POST",
        url: "/rest-auth/google/",
        body,
      }),
    }),
    signin: builder.mutation<SigninResult, SigninParams>({
      query: (body) => ({
        url: `/rest-auth/login/`,
        method: "POST",
        body,
      }),
    }),
    verifyInvite: builder.mutation<VerifyInviteResult, VerifParams>({
      query: (body) => ({
        url: `/invite/verify/`,
        method: "POST",
        body,
      }),
    }),
    addParticipant: builder.mutation<any, AddParticipantParams>({
      query: (body) => ({
        url: `/account_participants/add/`,
        method: "POST",
        body,
      }),
    }),
    createAccount: builder.mutation<any, CreateAccountParams>({
      query: (body) => ({
        url: `/dj-rest-auth/registration/`,
        method: "POST",
        body,
      }),
    }),
    termsAcknowledgement: builder.mutation<{ success: boolean }, { user_uuid: string }>({
      query: ({ user_uuid }) => ({
        url: `/settings/terms_of_use/${user_uuid}/`,
        method: "PUT",
      }),
    }),
    updateProfile: builder.mutation<UserDetails, Partial<CreateAccountParams>>({
      query: (body) => ({
        url: `/rest-auth/user/`,
        method: "PATCH",
        body,
      }),
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        dispatch(setUser({ ...data }));
      },
    }),
    patchUser: builder.mutation<UserDetails, Partial<UserDetails>>({
      query: (body) => ({
        url: `/rest-auth/user/`,
        method: "PATCH",
        body,
      }),
    }),
    get2faQrCode: builder.query<any, any>({
      query: () => ({
        url: `/2fa/qr/`,
        method: "GET",
        responseHandler: (response) => {
          return response.text();
        },
      }),
    }),
    get2faSecretCode: builder.query<any, any>({
      query: () => ({
        url: `/2fa/secret/`,
        method: "GET",
      }),
    }),
    toggle2fa: builder.mutation<null, Toggle2faParams>({
      query: ({ userId, twoFaCode, otp_enabled }) => ({
        method: "PUT",
        url: `/settings/2fa/${userId}/`,
        body: {
          otp_enabled,
          two_fa_code: twoFaCode,
        },
      }),
    }),
    send2faCode: builder.mutation<any, { access_token: string; otpCode: string }>({
      query: ({ access_token, otpCode }) => ({
        url: `/token/2fa/`,
        method: "POST",
        body: {
          access: access_token,
          otp_code: otpCode,
        },
      }),
    }),
    changePassword: builder.mutation<any, ChangePasswordFormFieldValues>({
      query: ({ oldPassword, newPassword, newPasswordRepeat }) => ({
        url: `/rest-auth/password/change/`,
        method: "POST",
        body: {
          old_password: oldPassword,
          new_password1: newPassword,
          new_password2: newPasswordRepeat,
        },
      }),
    }),
    getAllowance: builder.query<
      { allowance_data: Allowance },
      { chainId: ChainId; fromTokenAddress: string; account: string }
    >({
      query: ({ chainId, fromTokenAddress, account }) => ({
        url: "/dex/bot/token_allowance/",
        params: {
          network_id: chainId,
          token_address: fromTokenAddress,
          maker_address: account,
        },
      }),
    }),
    getExchangeSymbolMinAmountData: builder.query<GetExchangeSymbolMinAmountDataResult, { symbol: OrderSymbol }>({
      query: ({ symbol }) => ({
        url: "/exchanges_data/min_amount/",
        params: {
          symbol: symbol.symbol,
          exchange_name: symbol.exchange,
        },
      }),
    }),
    getWallets: builder.query<GetWalletsResult, null>({
      providesTags: ["WALLETS"],
      query: () => ({
        url: "/settings/wallets/",
      }),
      transformResponse: (response: { results: { wallets: null | Wallet[] }[] }) => ({
        results: response.results[0].wallets || [],
      }),
    }),
    updateWallets: builder.mutation<GetWalletsResult, { userId: string; wallets: Wallet[] }>({
      invalidatesTags: ["WALLETS"],
      query: ({ userId, wallets }) => ({
        url: `/settings/wallets/${userId}/`,
        method: "PUT",
        body: { wallets },
      }),
      async onQueryStarted({ wallets }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(walletsListOptimisticUpdate({ results: wallets }));
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getGasOracle: builder.query<GetGasOracleResult, { chainId: ChainId }>({
      query: ({ chainId }) => ({
        url: `/dex/bot/gas_oracle/`,
        params: { network_id: chainId, blocks: 100 },
      }),
    }),
    getMetricsMonths: builder.query<GetMetricsMonthsResult, null>({
      query: () => ({
        url: "/settings/metrics/",
      }),
    }),
    getMonthMetrics: builder.query<GetMonthMetricsResult, { month: string; tab: number }>({
      query: ({ month, tab }) => ({
        url: `/settings/metrics/${month}/`,
        params: { tab },
      }),
    }),
    getUserNotificationSettings: builder.query<UserNotificationSettings, { userId: string }>({
      providesTags: ["USER_NOTIFICATION_SETTINGS"],
      query: ({ userId }) => ({
        url: `/settings/notifications/${userId}/`,
      }),
    }),
    updateUserNotificationsSettings: builder.mutation<
      UserNotificationSettings,
      { userId: string; userNotificationSettings: UserNotificationSettings }
    >({
      invalidatesTags: ["USER_NOTIFICATION_SETTINGS"],
      query: ({ userId, userNotificationSettings }) => ({
        url: `/settings/notifications/${userId}/`,
        method: "PUT",
        body: userNotificationSettings,
      }),
    }),
    getMarketData: builder.query<GetMarketDataResult, GetMarketDataParams>({
      query: (params) => ({
        url: `/markets/ohlcv/`,
        params,
      }),
    }),
    getUserTokens: builder.query<UserToken[], { account: string; chainId: number }>({
      query: ({ account, chainId }) => ({
        url: `/dex/bot/get_user_token_list/`,
        params: {
          is_all: false,
          chain_id: SUPPORTED_CHAINS.find((x) => x.community_id === chainId)!.id,
          id: account,
        },
      }),
    }),
    getUserComplexProtocols: builder.query<ComplexProtocol[], { account: string; chainId: ChainId }>({
      query: ({ account, chainId }) => ({
        url: `/dex/bot/get_user_complex_protocol_list/`,
        params: {
          chain_id: SUPPORTED_CHAINS.find((x) => x.community_id === chainId)!.id,
          id: account,
        },
      }),
    }),
    getUserChainBalance: builder.query<{ usd_value: number }, { account: string; chainId: number }>({
      query: ({ chainId, account }) => ({
        url: "/dex/bot/get_user_chain_balance/",
        params: {
          id: account,
          chain_id: SUPPORTED_CHAINS.find((x) => x.community_id === chainId)!.id,
        },
      }),
    }),
    getUserTotalBalance: builder.query<{ total_usd_value: number }, { account: string }>({
      query: ({ account }) => ({
        url: "/dex/bot/get_user_total_balance/",
        params: {
          id: account,
        },
      }),
    }),
    getUserAccounts: builder.query<PaginationResult<UserAccountsResult>, null>({
      providesTags: ["USER_ACCOUNTS"],
      query: () => ({
        url: "/user_accounts/",
      }),
    }),
    createUserAccount: builder.mutation<UserAccountsResult, { name: string; referral_code?: string }>({
      invalidatesTags: ["USER_ACCOUNTS"],

      query: ({ name, referral_code }) => {
        const body = { name };

        if (referral_code) {
          body["referred_through_code"] = referral_code;
        }
        return {
          method: "POST",
          url: `/user_accounts/`,
          body,
        };
      },
    }),
    updateUserAccount: builder.mutation<UserAccountsResult, { id: string; name: string }>({
      invalidatesTags: ["USER_ACCOUNTS"],
      query: ({ name, id }) => ({
        method: "PUT",
        url: `/user_accounts/${id}`,
        body: { name },
      }),
    }),
    deleteUserAccount: builder.mutation<null, string>({
      invalidatesTags: ["USER_ACCOUNTS"],
      query: (id) => ({
        method: "DELETE",
        url: `/user_accounts/${id}/`,
      }),
    }),
    isTeamAccountExists: builder.query<{ name_is_available: boolean }, string>({
      query: (name) => ({
        method: "GET",
        url: `/account_is_available?name=${name}`,
      }),
    }),
    sendUserAccountInvite: builder.mutation<
      { results: Record<string, string> },
      { id: string; emails: string; readOnly?: boolean }
    >({
      invalidatesTags: ["USER_ACCOUNTS"],
      query: ({ id, emails, readOnly }) => ({
        method: "POST",
        url: `/invite/create_invite/`,
        body: { account_uuid: id, emails, readOnly },
      }),
    }),
    setMemberReadOnly: builder.mutation<any, { accountId: string; email: string; readOnly: boolean }>({
      invalidatesTags: ["USER_ACCOUNTS"],
      query: ({ accountId, email, readOnly }) => ({
        method: "POST",
        url: `/account_participants/set_permission/`,
        body: {
          user_email: email,
          account_uuid: accountId,
          read_only: readOnly,
        },
      }),
    }),
    sendCreateUserAccountRequest: builder.query<{ is_sent: boolean }, { user_uuid: string }>({
      query: ({ user_uuid }) => ({
        method: "POST",
        url: `/request_team_account/send_request/`,
        body: { user_uuid },
      }),
    }),
    removeUserFromAccount: builder.mutation<{ email: string; accountId: string }, { email: string; accountId: string }>(
      {
        invalidatesTags: ["USER_ACCOUNTS"],
        query: ({ email, accountId }) => ({
          method: "POST",
          url: `/account_participants/remove/`,
          body: {
            user_email: email,
            account_uuid: accountId,
          },
        }),
      }
    ),
    addWatchlistSymbol: builder.mutation<any, { symbol: FavoriteSymbol }>({
      query: ({ symbol }) => ({
        method: "POST",
        url: `/token/watchlist/add/`,
        body: {
          exchange: prepareExchange(symbol.exchange),
          symbol: symbol.symbol,
          asset_class: symbol.asset_class,
        },
      }),
      async onQueryStarted({ symbol }, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const tokenList = state.user.token_pair_watchlist;
        const loadingSymbol = {
          ...symbol,
          market_type: symbol.asset_class,
          exchange: CEFI_EXCHANGE_NAME[symbol.exchange],
        };

        dispatch(toggleLoadingSymbol(loadingSymbol));
        dispatch(setUserTokenWatchlist([...tokenList, symbol]));

        try {
          await queryFulfilled;
        } catch {
          dispatch(setUserTokenWatchlist(tokenList));
        }

        dispatch(toggleLoadingSymbol(loadingSymbol));
      },
    }),
    removeWatchlistSymbol: builder.mutation<null, { symbol: FavoriteSymbol }>({
      query: ({ symbol }) => ({
        method: "DELETE",
        url: `/token/watchlist/remove/`,
        body: {
          exchange: prepareExchange(symbol.exchange),
          symbol: symbol.symbol,
          asset_class: symbol.asset_class,
        },
      }),
      async onQueryStarted({ symbol }, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const tokenList = state.user.token_pair_watchlist;

        dispatch(toggleLoadingSymbol(symbol));
        dispatch(
          setUserTokenWatchlist(
            tokenList.filter((s) => {
              return (
                s.symbol !== symbol.symbol || s.exchange !== symbol.exchange || s.asset_class !== symbol.asset_class
              );
            })
          )
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(setUserTokenWatchlist(tokenList));
        }
        dispatch(toggleLoadingSymbol(symbol));
      },
    }),
    getExchangesSymbolData: builder.query<GetExchangesSymbolDataResponse, OrderSymbol>({
      query: ({ symbol, exchange, market_type }) => ({
        url: `/exchanges_data/exchange_symbol_data/`,
        params: {
          symbol,
          exchange_id: prepareExchange(exchange),
          market_type,
          is_randomize: true,
        },
      }),
    }),
    exportParentOrders: builder.mutation<Blob, boolean>({
      query: (isDefi) => ({
        url: `/v3/orders/export_to_csv/`,
        params: {
          is_defi: isDefi,
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
    exportAllParentOrders: builder.mutation<Blob, { account_uuid }>({
      query: (params) => ({
        url: `/v3/orders/export_parents/`,
        params,
        responseHandler: (response) => response.blob(),
      }),
    }),
    exportChildOrders: builder.mutation<Blob, MetricsPageOrderExportParams>({
      query: (params) => ({
        url: `/v3/orders/export_children/`,
        params,
        responseHandler: (response) => response.blob(),
      }),
    }),
    exportTrades: builder.mutation<Blob, MetricsPageOrderExportParams>({
      query: (params) => ({
        url: `/v3/orders/export_trades/`,
        params,
        responseHandler: (response) => response.blob(),
      }),
    }),
    submitParentCefiOrder: builder.mutation<ParentOrder, ParentOrderFormParams>({
      invalidatesTags: ["PARENT_CEFI_ORDERS"],
      query: (x) => {
        const startTime = DateTime.local().toUTC().valueOf();
        const isNeedToSetDefaultPlacementParameters =
          x.strategy === OrderExecutionStrategy.ICEBERG && x.tradingStyle === TradingStyle.HYBRID;

        return {
          method: "POST",
          url: `/v3/orders/add/`,
          body: {
            account_uuid: x.accountId,
            subaccount: x.account === MAIN_ACCOUNT_VALUE ? undefined : x.account,
            symbol: x.symbol?.symbol,
            exchange: prepareExchange(x.symbol?.exchange),
            asset_class: x.symbol?.market_type,
            side: x.side,
            quantity: x.quantity,
            strategy: x.strategy,
            start_time: startTime,
            expiration_time: x.expirationTime ? getExpirationTimeMs(x.expirationTime) : undefined,
            duration_seconds: x.tradingDuration
              ? getTradingDurationSec(Number(x.tradingDuration), x.tradingDurationUnit)
              : undefined,
            clip_size_type: (x.clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS
              ? ClipSizeType.PERCENTAGE
              : x.clipSizeType
            ).toLowerCase(),
            ...(x.clipSizeType !== ClipSizeType.AUTOMATIC
              ? {
                  clip_size_val: prepareNumericValue(
                    x.clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS
                      ? 100 / (Number(x.clipSizeValue) || 1)
                      : x.clipSizeValue
                  ),
                }
              : {}),
            limit_price: prepareNumericValue(x.limitPrice),
            params: {
              would_style: x.wouldPrice || x.wouldPct ? x.wouldStyle : undefined,
              would_price: prepareNumericValue(x.wouldPrice),
              would_pct: prepareNumericValue(x.wouldPct),
              trigger_price: prepareNumericValue(x.triggerPrice),
              ...(x.triggerPrice ? { trigger_condition: x.triggerCondition } : {}),
              trading_style: getTradingStyle(x.strategy, x.tradingStyle),
              placement_infos_placement_mode: isNeedToSetDefaultPlacementParameters
                ? PlacementMode.DEFAULT
                : x.placementMode,
              placement_infos_placement: prepareNumericValue(
                isNeedToSetDefaultPlacementParameters ? PlacementValue.DEFAULT : x.placement
              ),
              placement_infos_cancel: prepareNumericValue(
                isNeedToSetDefaultPlacementParameters ? PlacementCancelValue.DEFAULT : x.placementCancel
              ),
              extend_duration: x.extendDuration || false,
              ...(x.strategy === OrderExecutionStrategy.POV
                ? {
                    pov_risk: x.povRisk,
                    pov_ratio: +x.povRatio,
                  }
                : {}),
              ...(x.reduceOnly ? { reduce_only: x.reduceOnly } : {}),
              ...(x.strategy === OrderExecutionStrategy.IS ? { urgency: x.urgency } : {}),
            },
          },
        };
      },
      async onCacheEntryAdded(arg, api) {
        await api.cacheDataLoaded;
        reloadParentOrders(api, anbotoApi);
      },
    }),
    getParentCefiSavedOrders: builder.query<ParentOrder[], GetCefiParentOrdersParams>({
      providesTags: (result) =>
        result
          ? result.map(({ order_id }) => ({
              type: "PARENT_CEFI_SAVED_ORDERS",
              id: order_id,
            }))
          : ["PARENT_CEFI_SAVED_ORDERS"],
      query: ({ accountId, period, limit }) => ({
        url: `/v3/orders/view_saved_orders/`,
        params: {
          account_uuid: accountId,
          date_time_limit: period,
          limit,
        },
      }),
      transformResponse: ({ results }: PaginationResult<ParentOrder>) => {
        return results?.map((order) => {
          if (order.start_time) order.start_time = order.start_time * 1000;
          if (order.end_time) order.end_time = order.end_time * 1000;
          if (order.expiration_time) order.expiration_time = order.expiration_time * 1000;

          order.order_id = prepareNumericId(order.order_id);
          order.client_order_id = prepareNumericId(order.client_order_id);

          return order;
        });
      },
    }),
    getAllParentOrders: builder.query<PaginationResult<AllParentOrder>, GetAllParentOrdersParams>({
      providesTags: ["PARENT_CEFI_ORDERS"],
      query: ({ accountId, currentTab, period, limit, exchange_list_ids, offset, side, type, strategy }) => ({
        url: `/v3/orders/view_all_cefi_defi/`,
        params: {
          account_uuid: accountId,
          current_tab: currentTab,
          date_time_limit: period,
          limit,
          offset,
          exchange_list_ids,
          side,
          type,
          strategy,
        },
      }),
      transformResponse: (result: PaginationResult<AllParentOrder>) => {
        const results = result.results?.map((order) => {
          if (order.start_time) order.start_time = order.start_time * 1000;
          if (order.end_time) order.end_time = order.end_time * 1000;
          if (order.expiration_time) order.expiration_time = order.expiration_time * 1000;

          order.order_id = prepareNumericId(order.order_id || 0);
          order.client_order_id = prepareNumericId(order.client_order_id || 0);

          return order;
        });
        return { ...result, results };
      },
    }),

    getParentCefiOrders: builder.query<ParentOrder[], GetCefiParentOrdersParams>({
      providesTags: (result) =>
        result
          ? result.map(({ order_id }) => ({
              type: "PARENT_CEFI_ORDERS",
              id: order_id,
            }))
          : ["PARENT_CEFI_ORDERS"],
      query: ({ accountId, currentTab, period, status, limit, exchangeIds }) => ({
        url: `/v3/orders/view_all/`,
        params: {
          account_uuid: accountId,
          current_tab: currentTab,
          date_time_limit: period,
          limit,
          ...(exchangeIds ? { exchange_list_ids: exchangeIds.join(",") } : {}),
          ...(status === DefaultStatusFilter.All ? {} : { status: status }),
        },
      }),
      transformResponse: ({ results }: PaginationResult<ParentOrder>) => {
        return results?.map((order) => {
          if (order.start_time) order.start_time = order.start_time * 1000;
          if (order.end_time) order.end_time = order.end_time * 1000;
          if (order.expiration_time) order.expiration_time = order.expiration_time * 1000;

          order.order_id = prepareNumericId(order.order_id);
          order.client_order_id = prepareNumericId(order.client_order_id);

          return order;
        });
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, updateCachedData, dispatch }) {
        const refetching = { inProgress: false, symbols: {} };
        await cacheDataLoaded;

        const accountSub = getAccountSubscription(arg.accountId);

        const onMessage = ({ code, data }) => {
          updateCachedData((draft) => {
            if (data?.order_id) {
              try {
                const cefiOrder = draft.find((order) => {
                  return order.order_id === data.order_id;
                });

                if (cefiOrder) {
                  const {
                    average_price,
                    filled_quantity,
                    last_price,
                    last_quantity,
                    leaves_quantity,
                    status,
                    error_code,
                  } = data;

                  cefiOrder.last_status = {
                    ...cefiOrder.last_status,
                    ...(average_price ? { average_price } : {}),
                    ...(filled_quantity ? { filled_quantity } : {}),
                    ...(leaves_quantity !== undefined ? { leaves_quantity } : {}),
                    ...(last_price ? { last_price } : {}),
                    ...(last_quantity ? { last_quantity } : {}),
                    ...(status ? { status } : {}),
                    ...(error_code ? { error_code } : {}),
                  };
                } else {
                  const isDefiOrder = data?.is_defi;
                  const skipRefetchTab =
                    arg?.currentTab === OrdersCurrentTab.History &&
                    ![
                      ParentOrderStatuses.CANCELLED,
                      ParentOrderStatuses.PENDING_CANCEL,
                      ParentOrderStatuses.REJECTED,
                    ].includes(data.status?.toLowerCase() as ParentOrderStatuses);
                  const skipRefetch = skipRefetchTab || isDefiOrder;

                  // @TODO: implement fetching single data by id and add it to the list (when api ready)
                  if (code === ParentOrderMsgCode.CREATE && !refetching.inProgress && !skipRefetch) {
                    refetching.inProgress = true;
                    dispatch(
                      anbotoApi.endpoints.getParentCefiOrders.initiate(arg, { subscribe: false, forceRefetch: true })
                    ).then(() =>
                      setTimeout(() => {
                        refetching.inProgress = false;
                      }, 3000)
                    );
                  }
                }
              } catch (err) {
                console.log(err);
              }
            }
          });
        };

        accountSub?.listenAll(onMessage);

        await cacheEntryRemoved;

        accountSub?.stopListenAll(onMessage);
      },
    }),
    getCefiTriggerCount: builder.query<number, { accountId: string; exchangeIds: CefiExchangeId[] }>({
      providesTags: ["PARENT_CEFI_TRIGGER_COUNT"],
      query: ({ accountId, exchangeIds }) => ({
        url: `/v3/orders/view_all/`,
        params: {
          account_uuid: accountId,
          current_tab: OrdersCurrentTab.Trigger,
          date_time_limit: GetOrdersPeriod.All,
          ...(exchangeIds ? { exchange_list_ids: exchangeIds.join(",") } : {}),
          limit: 1,
        },
      }),
      transformResponse: ({ count }: PaginationResult<ParentOrder>) => {
        return count;
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, dispatch }) {
        await cacheDataLoaded;

        const accountSub = getAccountSubscription(arg.accountId);

        const refetch = _throttle(() => {
          dispatch(anbotoApi.endpoints.getCefiTriggerCount.initiate(arg, { subscribe: false, forceRefetch: true }));
        }, 3000);

        const onMessage = ({ data }) => {
          if (data?.strategy === OrderExecutionStrategy.LIMIT || !data?.is_defi) {
            refetch();
          }
        };

        accountSub?.listenAll(onMessage);

        await cacheEntryRemoved;

        accountSub?.stopListenAll(onMessage);
      },
    }),
    getGasAmountEstimation: builder.query<GetGasAmountEstimationResult, GetGasAmountEstimationParams>({
      providesTags: ["GAS_AMOUNT_ESTIMATION"],
      query: ({ chainId }) => ({
        url: `/v3/orders/gas_amount_estimation/`,
        params: {
          network_id: chainId,
        },
      }),
    }),
    cancelParentOrder: builder.mutation<UserAccountsResult, ParentOrderCancelParams>({
      query: ({ accountId, orderId }) => ({
        method: "POST",
        url: `/v3/orders/cancel/`,
        body: { account_uuid: accountId, order_id: orderId },
      }),
      async onCacheEntryAdded(arg, api) {
        await api.cacheDataLoaded;
        reloadParentOrders(api, anbotoApi);
      },
    }),
    pauseParentOrder: builder.mutation<null, { orderId: string; clientOrderId: string }>({
      invalidatesTags: ["PARENT_CEFI_ORDERS"],
      query: ({ orderId, clientOrderId }) => ({
        method: "POST",
        url: `/v3/orders/pause/`,
        body: {
          order_id: orderId,
          client_order_id: clientOrderId,
        },
      }),
    }),
    resumeParentOrder: builder.mutation<null, { orderId: string; clientOrderId: string }>({
      invalidatesTags: ["PARENT_CEFI_ORDERS"],
      query: ({ orderId, clientOrderId }) => ({
        method: "POST",
        url: `/v3/orders/unpause/`,
        body: {
          order_id: orderId,
          client_order_id: clientOrderId,
        },
      }),
    }),
    updateWatchlistSymbolOrder: builder.mutation<OrderSymbol[], { symbol: OrderSymbol; from: number; to: number }>({
      query: ({ symbol, to }) => ({
        method: "POST",
        url: `/token/watchlist/update_position/`,
        body: {
          exchange: prepareExchange(symbol.exchange),
          symbol: symbol.symbol,
          asset_class: symbol.market_type,
          position: to,
        },
      }),
      async onQueryStarted({ from, to }, { dispatch, queryFulfilled, getState }) {
        const state = getState() as RootState;
        const tokenList = state.user.token_pair_watchlist;

        const reordered = changeArrayOrder(tokenList, from, to) as FavoriteSymbol[];

        dispatch(setUserTokenWatchlist(reordered.map((symbol, index) => ({ ...symbol, position: index }))));

        try {
          await queryFulfilled;
        } catch {
          dispatch(setUserTokenWatchlist(tokenList));
        }
      },
    }),
    getFundingRates: builder.query<GetFundingRatesResult, GetFundingRatesParams | null>({
      query: (params: GetFundingRatesParams) => ({
        url: `/markets/funding_rates/`,
        params,
        method: "GET",
      }),
    }),
    saveOrderForLater: builder.mutation<{ success: true }, ParentOrderFormParams>({
      invalidatesTags: ["PARENT_CEFI_SAVED_ORDERS"],
      query: (x) => {
        const startTime = DateTime.local().toUTC().valueOf();
        return {
          method: "POST",
          url: `/v3/orders/save_for_later/`,
          body: {
            account_uuid: x.accountId,
            subaccount: x.account === MAIN_ACCOUNT_VALUE ? undefined : x.account,
            symbol: x.symbol?.symbol,
            exchange: prepareExchange(x.symbol?.exchange),
            asset_class: x.symbol?.market_type,
            side: x.side,
            quantity: x.quantity,
            strategy: x.strategy,
            start_time: startTime,
            expiration_time: x.expirationTime ? getExpirationTimeMs(x.expirationTime) : undefined,
            duration_seconds: x.tradingDuration
              ? getTradingDurationSec(Number(x.tradingDuration), x.tradingDurationUnit)
              : undefined,
            clip_size_type: x.clipSizeType.toLowerCase(),
            ...(x.clipSizeType !== ClipSizeType.AUTOMATIC
              ? { clip_size_val: prepareNumericValue(x.clipSizeValue) }
              : {}),
            limit_price: prepareNumericValue(x.limitPrice),
            params: {
              would_style: x.wouldPrice || x.wouldPct ? x.wouldStyle : undefined,
              would_price: prepareNumericValue(x.wouldPrice),
              would_pct: prepareNumericValue(x.wouldPct),
              trigger_price: prepareNumericValue(x.triggerPrice),
              ...(x.triggerPrice ? { trigger_condition: x.triggerCondition } : {}),
              trading_style: x.tradingStyle,
              placement_infos_placement_mode: x.placementMode,
              placement_infos_placement: prepareNumericValue(x.placement),
              placement_infos_cancel: prepareNumericValue(x.placementCancel),
              ...(x.strategy === OrderExecutionStrategy.IS ? { urgency: x.urgency } : {}),
            },
          },
        };
      },
    }),
    startSavedOrder: builder.mutation<{ success: boolean }, StartSavedOrderParams>({
      invalidatesTags: ["PARENT_CEFI_SAVED_ORDERS"],
      query: (body) => ({
        url: `/v3/orders/start_saved_order/`,
        method: "POST",
        body,
      }),
    }),
    deleteSavedOrder: builder.mutation<{ success: boolean }, DeleteSavedOrderParams>({
      invalidatesTags: ["PARENT_CEFI_SAVED_ORDERS"],
      query: (body) => ({
        url: `/v3/orders/delete_saved_order/`,
        method: "POST",
        body,
      }),
    }),
    getPromo: builder.query<GetPromosResult, null>({
      query: () => ({
        url: `/marketing/latest_ads/`,
        method: "GET",
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, dispatch }) {
        const { data } = await cacheDataLoaded;
        const popups = data?.popup_ads?.map((popup) => popup.uuid) || [];
        const banners = data?.banner_ads?.map((banner) => banner.uuid) || [];
        if (data) {
          dispatch(updatePromos({ promos: { banners, popups } }));
        }
      },
    }),
    getMetricsTrade: builder.query<GetMetricsTradeResult, GetMetricsTradeParams>({
      query: (params) => ({
        method: "GET",
        url: `/v3/orders/metrics_trades`,
        params,
      }),
    }),
    getMetricsTradesGrouped: builder.query<GetMetricsTradesGroupedResult, GetMetricsTradesGroupedParams>({
      query: (params) => ({
        method: "GET",
        url: `/v3/orders/metrics_trades_grouped`,
        params,
      }),
    }),
    validateApiKey: builder.query<ValidateApiKeyResult, ValidateApiKeyParams>({
      query: ({ api_uuid }) => ({
        url: `/settings/api_keys/validate_one/${api_uuid}/`,
        method: "GET",
      }),
    }),
    getPostTradeAnalysisData: builder.query<GetPostTradeAnalysisDataResult, GetPostTradeAnalysisDataParams>({
      query: (params) => ({
        url: `/v3/orders/post_trade_analysis/`,
        params,
        method: "GET",
      }),
    }),
    getPostTradeAnalysisMetricsData: builder.query<
      GetPostTradeAnalysisMetricsDataResult,
      GetPostTradeAnalysisMetricsDataParams
    >({
      query: (params) => ({
        url: `/v3/orders/post_trade_analysis/sum_ptas/`,
        params,
        method: "GET",
      }),
    }),
    getLeaderBoard: builder.query<GetLeaderBoardResult, null>({
      query: () => ({
        url: `/v3/orders/leaderboard/`,
        method: "GET",
      }),
    }),
    setOrderPreferences: builder.query<any, SetOrderPreferencesParams>({
      query: (params) => ({
        url: `/order_preference/${params.team_uuid}/`,
        body: { ...params, ...{ team_uuid: undefined } },
        method: "PUT",
      }),
      onCacheEntryAdded: async (arg, api) => {
        await api.cacheDataLoaded;
        const cacheEntry = api.getCacheEntry();
        if (cacheEntry.data) {
          const orderPreference = { ...cacheEntry.data.data };
          api.dispatch(setOrderPreferences(orderPreference));
        }
      },
    }),
    getDailyDashboard: builder.query<GetDailyDashboardResult, { date: string }>({
      query: (params) => ({
        url: `/v3/fees_dashboard/get_daily_dashboard`,
        method: "GET",
        params,
      }),
    }),
    getMonthlyDashboard: builder.query<GetMonthlyDashboardResult, { month: string }>({
      query: (params) => ({
        url: `/v3/fees_dashboard/get_monthly_dashboard`,
        method: "GET",
        params,
      }),
    }),
    getFeeManagerBalances: builder.query<GetFeeManagerBalancesResults, void>({
      query: () => ({
        url: `/v3/fees_dashboard/get_fee_manager_balances`,
        method: "GET",
      }),
    }),
    getExecContractsBalances: builder.query<any, void>({
      query: () => ({
        url: `/v3/fees_dashboard/get_exec_contract_balances`,
        method: "GET",
      }),
    }),
    getFeeManagerAccountsBalances: builder.query<any, void>({
      query: () => ({
        url: `/v3/fees_dashboard/get_fee_manager_accounts_balances/`,
        method: "GET",
      }),
    }),
  }),
});

export const walletsListOptimisticUpdate = (data) =>
  anbotoApi.util.updateQueryData("getWallets", null, (draft) => {
    Object.assign(draft, data);
  });

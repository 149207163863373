import { AxisTickProps } from "@src/pages/analytics/types";
import { dateFormatter } from "@src/pages/metrics/helpers";
import { GroupBy } from "@src/store/slices/metricsSlice";

type DateTickProps = {
  tickerRange: GroupBy;
} & AxisTickProps;

const DateTick = ({ x, y, payload, tickerRange }: DateTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text fontSize={12} x={0} y={0} dy={16} textAnchor="end" fill="white" transform="rotate(-45)">
      {dateFormatter(payload.value, tickerRange)}
    </text>
  </g>
);

export default DateTick;

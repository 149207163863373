import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTheme } from "@mui/material";
import SideChartTooltip from "@src/pages/metrics/components/side-chart/side-chart-tooltip";
import { COLORS } from "@src/pages/portfolio/constant";
import { SideChartItem } from "@src/store/apis/anbotoApi/types";
import DateTick from "@src/pages/metrics/components/date-tick/date-tick";
import { formatUsdBigAmounts } from "@src/pages/metrics/helpers";
import { useAppSelector } from "@src/store/hooks";

type SideChartProps = {
  chartData: SideChartItem[];
};

const SideChart = ({ chartData }: SideChartProps) => {
  const theme = useTheme();
  const tickerRange = useAppSelector((state) => state.metrics.tradesMetricsFilters.groupBy);

  return (
    <ResponsiveContainer width="100%" height={250}>
      <ComposedChart
        width={500}
        height={200}
        data={chartData}
        stackOffset="sign"
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={30}
      >
        <CartesianGrid stroke={theme.custom.background.secondary} vertical={false} strokeDasharray="5 5" />
        <XAxis height={60} tick={(props) => <DateTick tickerRange={tickerRange} {...props} />} dataKey="date" />
        <YAxis orientation="left" tickFormatter={formatUsdBigAmounts} />
        <YAxis yAxisId="netAxis" dataKey="net" orientation="right" tickFormatter={formatUsdBigAmounts} />
        <Tooltip content={<SideChartTooltip />} cursor={{ fill: "transparent" }} />
        <ReferenceLine y={0} stroke="#fff" />
        <Bar dataKey="buy" fill={COLORS[1]} stackId="stack" />
        <Bar dataKey="sell" fill={COLORS[2]} stackId="stack" />
        <Line yAxisId="netAxis" dataKey="net" stroke={COLORS[0]} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default SideChart;

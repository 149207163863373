import { CircularProgress, Stack } from "@mui/material";

const AnbotoLoader = () => {
  return (
    <Stack flexGrow={1}>
      <Stack justifyContent="center" flexGrow={1} padding={3} alignItems="center">
        <CircularProgress />
      </Stack>
    </Stack>
  );
};

export default AnbotoLoader;

import { Card, Skeleton, Stack, styled, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { useLocation, useNavigate } from "react-router-dom";
import { getPortfolioTypeValue } from "../../../utils";
import EmptyPieChart from "./empty-pie-chart.svg?react";
import { PortfolioType } from "@src/store/slices/portfolioSlice";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { ChartNames, COLORS } from "@src/pages/portfolio/constant";
import ChartTooltip from "@src/pages/portfolio/components/charts/pie-chart-card/chart-tooltip";

type PieChartCardProps = {
  chartName: ChartNames | string;
  chartData: Record<string, string | number>[];
  isFetching?: boolean;
  noData?: boolean;
  linkButton?: boolean;
};

export const PieChartCard = ({ linkButton, noData, chartName, chartData, isFetching }: PieChartCardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const type = getPortfolioTypeValue(location.pathname);

  return (
    <PieChartCardRoot variant="outlined">
      <Typography fontSize={14} fontWeight={500} color="text.secondary">
        {chartName !== ChartNames.TokenAllocation &&
        chartName !== ChartNames.ExchangesAllocation &&
        chartName !== ChartNames.ProtocolsAllocation
          ? chartName + " allocation"
          : chartName}
      </Typography>
      {isFetching ? (
        <Stack paddingTop={4} display="flex" flexDirection="row">
          <Skeleton variant="circular" width={120} height={120} />
          <Stack marginLeft={2} flexGrow={1}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Stack>
        </Stack>
      ) : chartData?.length > 0 ? (
        <StyledPieChart data={chartData} />
      ) : (
        <Stack
          paddingTop={5}
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <EmptyPieChart width={125} />
          {chartName === ChartNames.TokenAllocation || noData ? (
            <Typography marginTop={1} fontSize={14} fontWeight={400} color="text.secondary">
              You don’t have any token allocation yet
            </Typography>
          ) : (
            <>
              <Typography marginTop={1} fontSize={14} fontWeight={400} color="text.secondary">
                You don’t have any {type === PortfolioType.CEFI ? "exchange" : "wallet"} allocation yet
              </Typography>
              {linkButton && (
                <AnbotoButton
                  variant="outlined"
                  sx={{ mt: 2, width: 160, height: 32, fontSize: 14 }}
                  onClick={() => navigate(`/settings/linked/${type === PortfolioType.CEFI ? "exchanges" : "wallets"}`)}
                >
                  Link {type === PortfolioType.CEFI ? "exchange" : "wallet"}
                </AnbotoButton>
              )}
            </>
          )}
        </Stack>
      )}
    </PieChartCardRoot>
  );
};

type StyledPieChartProps = {
  data: any[] & {};
};

const Bullet = ({ color }: any) => {
  return (
    <div
      style={{
        borderRadius: "50%",
        backgroundColor: color,
        width: 12,
        height: 12,
      }}
    ></div>
  );
};

const RenderLegend = (props: any) => {
  const { payload } = props;
  return (
    <Stack gap={1}>
      {payload!.map((entry, index) => {
        return (
          <Stack width="100%" display="flex" direction="row" key={index} justifyContent="space-between">
            <Stack alignItems="center" display="flex" flexDirection="row">
              <Bullet color={COLORS[index % COLORS.length]} />
              <Typography fontSize={14} fontWeight={500} pl={1} noWrap>
                {Math.round(entry.payload.percent * 100)} %
              </Typography>
            </Stack>
            <Typography
              paddingLeft={1}
              textTransform="capitalize"
              fontSize={14}
              fontWeight={400}
              pl={3}
              noWrap
              color="text.secondary"
            >
              {entry.payload.name}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

const StyledPieChart = ({ data, ...props }: StyledPieChartProps) => {
  return (
    <PieChart {...props} width={400} height={190}>
      <Pie
        data={data}
        cx={90}
        cy={90}
        innerRadius={40}
        outerRadius={60}
        paddingAngle={0.6}
        dataKey="value"
        cursor="pointer"
        isAnimationActive={false}
      >
        {data.map((entry, index) => (
          <Cell key={index} fill={COLORS[index % COLORS.length]} strokeWidth="0" />
        ))}
      </Pie>
      <Tooltip content={<ChartTooltip />} />

      <Legend
        align="right"
        layout="vertical"
        width={190}
        verticalAlign="middle"
        iconType="circle"
        content={<RenderLegend />}
      />
    </PieChart>
  );
};

const PieChartCardRoot = styled(Card)({
  padding: "10px 20px",
  width: 442,
  height: 240,
  ["p:nth-of-type(1):first-letter"]: {
    textTransform: "uppercase",
  },
});

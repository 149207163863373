import { useEffect, useState } from "react";
import { Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import _pick from "lodash/pick";
import MonthlySummaryTable from "../../components/monthly-summary-table/monthly-summary-table";
import TradesSummary from "../../components/trades-summary/trades-summary";
import TakerVsMakerChart from "../../components/taker-vs-maker-chart/taker-vs-maker-chart";
import TradesChart from "../../components/trades-chart/trades-chart";
import SideChart from "../../components/side-chart/side-chart";
import TradesFilters from "../../components/trade-filters/trades-filters";
import { TRADING_TYPES } from "../../constant";
import { prepareMetricsData } from "./get-charts-data";
// import { fakeData } from "./data";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import AnbotoFetchingError from "@src/components/anboto-fetching-error/anboto-fetching-error";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { anbotoOrders } from "@src/store/apis/anbotoApi/orders";
import { MonthlySummaryTableItem } from "@src/store/apis/anbotoApi/types";
import { PieChartCard } from "@src/pages/portfolio/components/charts/pie-chart-card";
import { setFilterOptions, setFilters } from "@src/store/slices/metricsSlice";
import { MarketType } from "@src/features/exchanges/types";

// const { data, isLoading, isError } = fakeData;

const TradingStatsV2 = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.metrics.tradesMetricsFilters);

  const [tableData, setTableData] = useState<MonthlySummaryTableItem[]>([]);
  const [info, setInfo] = useState<{ maker: number; taker: number }>({
    maker: 0,
    taker: 0,
  });
  const [exchangesData, setExchangesData] = useState([]);
  const [chartsData, setChartsData] = useState<MonthlySummaryTableItem[]>([]);

  const start = dayjs().subtract(36, "months").format("YYYY-MM-DD");
  const end = dayjs().format("YYYY-MM-DD");

  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const thisMonthTradingVolume = tableData.length ? tableData[0].volume : 0;
  const totalTradingVolume = tableData.length ? tableData[tableData.length - 1].cumulativeVolume : 0;

  const volumeChartData = chartsData.map(({ volume, cumulativeVolume, date }) => ({
    trades: volume,
    cumulativeSum: cumulativeVolume,
    date,
  }));

  const volumeNetChartData = chartsData.map(({ buy, sell, date }) => ({
    buy,
    sell,
    net: buy - sell,
    gross: buy - sell,
    date,
  }));

  const tradingTypeChartData = tableData.reduce(
    (res, { cefi, defi }) => ({
      cefi: res.cefi + cefi,
      defi: res.defi + defi,
    }),
    {
      cefi: 0,
      defi: 0,
    }
  );

  const { data, isLoading, isError } = anbotoOrders.useGetDailyMetricsQuery({
    start,
    end,
  });

  const getPeriod = (key: string) => {
    const obj = {
      months: "month",
      days: "day",
      weeks: "week",
    };

    return obj[key];
  };

  // filtered info
  useEffect(() => {
    if (data?.data) {
      const { table } = prepareMetricsData(
        data.data,
        getPeriod(filters.groupBy),
        _pick(filters, ["exchanges", "strategies", "subaccounts", "tradeTypes", "assetClasses"])
      );

      const currentData = table;

      let cumulativeVolume = 0;

      const _chartsData: MonthlySummaryTableItem[] = Object.keys(table)
        .sort((a, b) => dayjs(a, "YYYY-MM-DD").valueOf() - dayjs(b, "YYYY-MM-DD").valueOf())
        .map((key) => {
          const cur = currentData[key];

          cumulativeVolume += cur.volume;

          return {
            sell: cur.sell,
            buy: cur.buy,
            volume: cur.volume,
            cumulativeVolume,
            net: cur.buy - cur.sell,
            tradedMaker: cur.maker,
            tradedTaker: cur.taker,
            cefi: cur.cefi,
            defi: cur.defi,
            date: dayjs(key, "YYYY-MM-DD").valueOf(),
            month: dayjs(key, "YYYY-MM-DD").format("MMM YYYY"),
          };
        });

      setChartsData(_chartsData);
    }
  }, [data, JSON.stringify(Object.values(filters))]);

  // month info
  useEffect(() => {
    if (data?.data) {
      let cumulativeVolume = 0;
      const { table, exchanges, subaccounts, strategies } = prepareMetricsData(data.data, "month");

      dispatch(
        setFilterOptions({
          exchanges: Object.keys(exchanges),
          strategies: Object.keys(strategies),
          subaccounts: Object.keys(subaccounts),
        })
      );

      dispatch(
        setFilters({
          subaccounts: Object.keys(subaccounts),
          tradeTypes: TRADING_TYPES,
          strategies: Object.keys(strategies),
          assetClasses: [MarketType.SPOT, MarketType.FUTURE],
          exchanges: Object.keys(exchanges),
        })
      );

      const preparedData = Object.keys(table)
        .sort((a, b) => dayjs(a, "YYYY-MM-DD").valueOf() - dayjs(b, "YYYY-MM-DD").valueOf())
        .map((key) => {
          const cur = table[key];

          cumulativeVolume += cur.volume;

          return {
            sell: cur.sell,
            buy: cur.buy,
            volume: cur.volume,
            cumulativeVolume,
            net: cur.buy - cur.sell,
            tradedMaker: cur.maker,
            tradedTaker: cur.taker,
            cefi: cur.cefi,
            defi: cur.defi,
            date: dayjs(key, "YYYY-MM-DD").valueOf(),
            month: dayjs(key, "YYYY-MM-DD").format("MMM YYYY"),
          };
        })
        .sort((a, b) => b.date - a.date);

      setTableData(preparedData);
      setInfo({
        taker: preparedData.reduce((res, row) => (res += row.tradedTaker), 0),
        maker: preparedData.reduce((res, row) => (res += row.tradedMaker), 0),
      });
      setExchangesData(Object.keys(exchanges).map((name) => ({ name, value: exchanges[name].value })));
    }
  }, [data]);

  return (
    <Paper sx={{ maxWidth: "99.9%", flexGrow: 1, padding: 3, display: "flex" }}>
      <Stack maxWidth="99%" flexGrow={1} flexDirection="column" gap={1}>
        {isLoading ? (
          <AnbotoLoader />
        ) : isError ? (
          <AnbotoFetchingError />
        ) : data ? (
          <>
            <Stack maxWidth="90%">
              <TradesSummary thisMonthVolume={thisMonthTradingVolume} totalVolume={totalTradingVolume} />
              <Stack maxWidth="99%" direction={isXlUp ? "row" : "column"} gap={isXlUp ? 0 : 2}>
                <Stack direction="column" gap={2} width={isXlUp ? "70%" : "100%"}>
                  <TradesFilters hideSides />
                  <Typography variant="h6" fontWeight="bold">
                    Volume Traded (Gross)
                  </Typography>
                  <TradesChart tradesChartData={volumeChartData} />
                  <Typography variant="h6" fontWeight="bold">
                    Volume Traded (Net)
                  </Typography>
                  <SideChart chartData={volumeNetChartData.map((el) => ({ ...el, sell: -el.sell || 0 }))} />
                </Stack>
                <Stack
                  width={isXlUp ? "30%" : "100$"}
                  direction={isXlUp ? "column" : "row"}
                  gap={2}
                  justifyContent="space-between"
                >
                  <PieChartCard
                    noData
                    isFetching={false}
                    chartName="Trading type"
                    chartData={[
                      {
                        name: "DEFI",
                        value: tradingTypeChartData.defi,
                      },
                      { name: "CEFI", value: tradingTypeChartData.cefi },
                    ]}
                  />
                  <PieChartCard noData chartName="Exchanges" chartData={exchangesData} />

                  {isXlUp && (
                    <TakerVsMakerChart
                      takerVsMakerRate={{
                        taker_value: info.taker,
                        maker_value: info.maker,
                        taker: (info.taker * 100) / (info.taker + info.maker),
                        maker: (info.maker * 100) / (info.taker + info.maker),
                      }}
                    />
                  )}
                </Stack>
                {!isXlUp && (
                  <Stack marginTop={2}>
                    <TakerVsMakerChart
                      takerVsMakerRate={{
                        taker_value: info.taker,
                        maker_value: info.maker,
                        taker: (info.taker * 100) / (info.taker + info.maker),
                        maker: (info.maker * 100) / (info.taker + info.maker),
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack marginTop={2}>
              <Typography variant="h6" fontWeight="bold">
                Your trading Metrics (table format)
              </Typography>
              <MonthlySummaryTable data={tableData} />
            </Stack>
          </>
        ) : null}
      </Stack>
    </Paper>
  );
};

export default TradingStatsV2;

import {
  Stack,
  DialogTitle,
  IconButton,
  Typography,
  styled,
  ListItemText,
  List,
  ListItemProps,
  ListItem,
  DialogContent,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { ORDER_EXECUTION_STRATEGY_DESCRIPTION, ORDER_EXECUTION_STRATEGY_NAME } from "./StrategySelect";
import { CardDialog } from "@src/components/CardDialog";
import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";

type StyledListItemProps = ListItemProps & { isSelected: boolean };

export const StrategyDialog = ({
  open,
  hide,
  value,
  onSelect,
  strategies,
}: {
  value: OrderExecutionStrategy;
  open: boolean;
  hide: () => void;
  onSelect: (strategy: OrderExecutionStrategy) => void;
  strategies: OrderExecutionStrategy[];
}) => (
  <CardDialog open={open} sx={{ zIndex: 3 }}>
    <StyledDialogTitle>
      <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
        <Typography variant="h6">Select strategy</Typography>
        <IconButton size="small" onClick={hide}>
          <Close />
        </IconButton>
      </Stack>
    </StyledDialogTitle>
    <StyledDialogContent>
      <List disablePadding>
        {strategies.map((x) => (
          <StyledListItem key={x} isSelected={x === value} onClick={() => onSelect(x)}>
            <ListItemText
              primary={ORDER_EXECUTION_STRATEGY_NAME[x]}
              secondary={ORDER_EXECUTION_STRATEGY_DESCRIPTION[x]}
            />
          </StyledListItem>
        ))}
      </List>
    </StyledDialogContent>
  </CardDialog>
);

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: "#232C2F",
});

const StyledListItem = styled(ListItem, { shouldForwardProp: (x) => x !== "isSelected" })<StyledListItemProps>(
  ({ theme, isSelected }) => ({
    backgroundColor: "#192022",
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    border: isSelected ? `1px solid #2C72E3` : "initial",
    borderRadius: 4,
  })
);

export const StyledDialogContent = styled(DialogContent)({
  padding: 16,
  paddingTop: "16px !important",
});

/* eslint-disable react/display-name */
import React from "react";
import { createColumnHelper, Row } from "@tanstack/table-core";
import { MonthlySummaryTableItem } from "@src/store/apis/anbotoApi/types";
import { formatUsd } from "@src/utils/format";

export const columnHelper = createColumnHelper<MonthlySummaryTableItem>();

export const getColumns = () => [
  columnHelper.accessor("month", {
    id: "month",
    header: "Dates",
    size: 100,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{row.original.month || ""}</>,
      (prev, next) => prev.row.original.month === next.row.original.month
    ),
    sortingFn: (a, b) => a.original.date - b.original.date,
  }),
  columnHelper.accessor("volume", {
    id: "volume",
    header: "Traded (Gross)",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.volume || 0)}</>,
      (prev, next) => prev.row.original.volume === next.row.original.volume
    ),
    footer: ({ table }) =>
      formatUsd(
        table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("tradedValue") as number), 0)
      ),
  }),
  columnHelper.accessor("cumulativeVolume", {
    id: "cumulativeVolume",
    header: "Traded (Gross) - Cumulative",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.cumulativeVolume || 0)}</>,
      (prev, next) => prev.row.original.cumulativeVolume === next.row.original.cumulativeVolume
    ),
    footer: ({ table }) =>
      formatUsd(
        table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("tradedValue") as number), 0)
      ),
  }),
  columnHelper.accessor("buy", {
    id: "buy",
    header: "Traded - Buy",
    size: 75,
    cell: ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row?.original.buy || 0)}</>,
    footer: ({ table }) =>
      formatUsd(
        table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("buyValue") as number), 0)
      ),
  }),
  columnHelper.accessor("sell", {
    id: "sell",
    header: "Traded - Sell",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row?.original.sell || 0)}</>,
      (prev, next) => prev.row.original.sell === next.row.original.sell
    ),
    footer: ({ table }) =>
      formatUsd(
        table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("sellValue") as number), 0)
      ),
  }),
  columnHelper.accessor("net", {
    id: "net",
    header: "Traded - Net",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.net || 0)}</>,
      (prev, next) => prev.row.original.net === next.row.original.net
    ),
    footer: ({ table }) =>
      formatUsd(table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("net") as number), 0)),
  }),
  columnHelper.accessor("tradedMaker", {
    id: "tradedMaker",
    header: "Traded (Gross) - Maker",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.tradedMaker || 0)}</>,
      (prev, next) => prev.row.original.tradedMaker === next.row.original.tradedMaker
    ),
    footer: ({ table }) =>
      formatUsd(
        table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("tradedMaker") as number), 0)
      ),
  }),
  columnHelper.accessor("tradedTaker", {
    id: "tradedTaker",
    header: "Traded (Gross) - Taker",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.tradedTaker || 0)}</>,
      (prev, next) => prev.row.original.tradedTaker === next.row.original.tradedTaker
    ),
    footer: ({ table }) =>
      formatUsd(
        table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("tradedTaker") as number), 0)
      ),
  }),
  columnHelper.accessor("cefi", {
    id: "cefi",
    header: "CEFI % Total (Gross)",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => (
        <>{((row.original?.cefi * 100) / row.original?.volume).toFixed(0) + " %"}</>
      ),
      (prev, next) => prev.row.original.cefi === next.row.original.cefi
    ),
    footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows;
      const total = rows.reduce((total, row) => total + row.original.cefi, 0);
      const cumulative = rows[0].original.cumulativeVolume;

      return ((total * 100) / cumulative).toFixed(0) + "%";
    },
  }),
  columnHelper.accessor("defi", {
    id: "defi",
    header: "DEFI % Total (Gross)",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => (
        <>{((row.original?.defi * 100) / row.original.volume).toFixed(0) + " %"}</>
      ),
      (prev, next) => prev.row.original.defi === next.row.original.defi
    ),
    footer: ({ table }) => {
      const rows = table.getFilteredRowModel().rows;
      const total = rows.reduce((total, row) => total + row.original.defi, 0);
      const cumulative = rows[0].original.cumulativeVolume;

      return ((total * 100) / cumulative).toFixed(0) + "%";
    },
  }),
];
